import λ from '~/utils/invoker';
import { getId } from '#/object-id';
import ListMixin from '~/mixins/list';
import { routeNames } from '~/router';
import PermissionMixin from '~/mixins/permission';
import PaginatedTable from '~/components/paginated-table.vue';
import ConfirmDialog from '~/components/confirm-dialog.vue';
import GoBackWrapper from '~/components/go-back-wrapper.vue';
import { mdiTrashCanOutline, mdiPlusCircleOutline, mdiMagnify, mdiDownload } from '@mdi/js';
import { creditTypes } from '#/credit';
import { map } from 'ramda';
import CreditForListMixin from '~/mixins/creditForList';
const servicesλ = λ.namespaced('services');
export default {
    name: 'service-list',
    mixins: [
        ListMixin('services'),
        CreditForListMixin('service', 'allServices'),
        PermissionMixin,
    ],
    components: {
        ConfirmDialog,
        GoBackWrapper,
        PaginatedTable,
    },
    data() {
        return {
            mdiMagnify,
            mdiTrashCanOutline,
            mdiPlusCircleOutline,
            mdiDownload,
            creditTypes,
            query: {
                sortBy: 'lastName',
                sortDesc: false,
                page: 1,
                itemsPerPage: 10,
                filters: {
                    search: null,
                },
            },
        };
    },
    computed: {
        allServices: servicesλ.state('all'),
        totalCount: servicesλ.state('totalCount'),
        headers() {
            const headers = [
                { text: this.$t('ID'), value: 'reference' },
                { text: this.$t('Name'), value: 'name' },
                { text: this.$t('City'), value: 'address.city' },
            ];
            const creditHeaders = map(creditType => {
                return {
                    text: `${this.$t('Credit total/used/remain')} [${this.$t(`creditType.${creditType}`)}]`,
                    sortable: false,
                };
            }, creditTypes);
            return [
                ...headers,
                ...creditHeaders,
                ...(this.canDeleteServices ? [{ text: this.$t('Delete'), sortable: false, }] : []),
            ];
        },
    },
    methods: {
        downloadExport: servicesλ.action('downloadExport'),
        remove: servicesλ.action('remove'),
        openItem(item) {
            if (this.canReadServices || this.canReadOwnService(getId(item))) {
                this.$router.push({
                    name: routeNames.services.edit,
                    params: { id: getId(item) }
                });
            }
        },
        addNewItem() {
            this.$router.push({
                name: routeNames.services.create,
            });
        },
        removeService(service) {
            this.$refs.deleteDialog
                .open()
                .then(async (doDelete) => {
                if (doDelete) {
                    await this.remove(service);
                }
            });
        },
    },
};
