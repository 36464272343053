import λ from '~/utils/invoker';
import { fetchAll } from '~/utils/crud-functions';
import parachute from '~/utils/parachute';
import { endOfYear, startOfYear } from 'date-fns';
import { getId } from '#/object-id';
import { evaluateTotalCredit } from '#/credit';
export default function creditForListMixin(itemProp, listProp) {
    return {
        data() {
            return {
                usedCreditsByItemAndType: {},
            };
        },
        watch: {
            [listProp]: {
                handler() {
                    this.usedCreditsByItemAndType = {};
                    this.surveyParticipations();
                },
                immediate: true,
            },
            selectedCreditYear: {
                handler() {
                    this.usedCreditsByItemAndType = {};
                    this.surveyParticipations();
                },
                immediate: true,
            },
        },
        computed: {
            selectedCreditYear: λ.vuexState('year'),
            selectedCreditYearRange() {
                const base = new Date(this.selectedCreditYear, 0, 1);
                return {
                    start: startOfYear(base),
                    end: endOfYear(base),
                };
            },
        },
        methods: {
            surveyParticipations: parachute(async function surveyParticipations() {
                var _a;
                if (!((_a = this[listProp]) === null || _a === void 0 ? void 0 : _a.length)) {
                    return;
                }
                const results = await fetchAll('participations/survey', {
                    groupBy: itemProp,
                    filters: {
                        [itemProp]: this[listProp].map(getId),
                        range: this.selectedCreditYearRange,
                    },
                });
                this.usedCreditsByItemAndType = results;
            }),
            getAssignedCredit(item, type) {
                var _a;
                const assignedCredit = (_a = item === null || item === void 0 ? void 0 : item.credits) === null || _a === void 0 ? void 0 : _a.find(credit => credit.year === this.selectedCreditYear && credit.type === type);
                return assignedCredit ? evaluateTotalCredit(assignedCredit) : '-';
            },
            getUsedCredit(item, type) {
                var _a, _b, _c, _d;
                return (_d = (_c = (_b = (_a = this.usedCreditsByItemAndType) === null || _a === void 0 ? void 0 : _a[getId(item)]) === null || _b === void 0 ? void 0 : _b[type]) === null || _c === void 0 ? void 0 : _c.credits) !== null && _d !== void 0 ? _d : '-';
            },
            getRemainingCredit(item, type) {
                const used = Number(this.getUsedCredit(item, type));
                const assigned = Number(this.getAssignedCredit(item, type));
                return Number.isNaN(used) || Number.isNaN(assigned) ? '-' : assigned - used;
            },
        },
    };
}
;
